<template>
  <nav class="navbar navbar-light" style="background-color: #E13122;">
    <span
      class="navbar-brand mb-0 h1"
      style="color:#ffffff;font-size:2rem"
      >Matchday</span
    >
  </nav>
  <div id="terms" style="background-color:#f4f4f4 !important">
    <br />
    <div class="row no-gutter">
      <div
        class="container"
        style="background-color:white !important;border-radius:25px"
      >
        <div class="row no-gutters">
          <div class="col-12 ml-2" style="text-align:center">
            <div class="text-center">
              <img
                id=""
                class=""
                src="https://prod-media-as1.s3.ap-southeast-1.amazonaws.com/miscellaneous/Matchday_Logo-01_jlclfy.jpg"
                style="height: 100px; width: 100px; border-radius: 25%"
              />
              <br />
            </div>
          </div>
          <div class="col-auto ml-2" style="text-align:center">
            <span style="font-size: 24px">
              <b
                >เงื่อนไขและข้อกำหนดการให้บริการสำหรับผู้จองและเล่นกีฬาและ/หรือสถานบริการกีฬา
              </b></span
            >
          </div>
          <div class="col-12 mx-3">
            <div class="divider mb-3 mt-3"></div>
          </div>

          <div class="col-12 mx-3">
            <p style="font-size: 14px">
              ปรับปรุงครั้งล่าสุดวันที่ 9 พฤษภาคม 2563
            </p>
          </div>
          <div class="col-12">
            <div class="detail">
              <p class="c" style="margin-left: 20px; margin-right: 20px">
                <span style="font-size: 16px">
                  <b>1. บทนำ </b><br /><br />
                  ยินดีต้อนรับสู่แพลตฟอร์มสำหรับ
                  การบริหารและจองสถานบริการกีฬาเพื่อเล่นกีฬา (แพลตฟอร์ม)
                  ที่ให้บริการและควบคุมโดย บริษัท แมทช์เดย์ ฮับ จำกัด
                  (เรียกแยกและเรียกรวมกันว่า MATCHDAY, เรา, พวกเรา หรือ ของเรา)
                  กรุณาอ่านเงื่อนไขการให้บริการอย่างละเอียดก่อนการสร้างบัญชี
                  (บัญชี หรือ แอคเค้าท์) การเข้าสู่ และการเข้าสู่แพลตฟอร์ม
                  MATCHDAY เพื่อให้ทราบถึงสิทธิ หน้าที่
                  และข้อผูกพันตามกฎหมายที่เกี่ยวเนื่องกับบริการของเราที่จัดไว้ให้หรือทำให้ใช้งานได้รวมถึง
                  แพลตฟอร์ม และ ข้อมูลรวมถึงไม่จำกัดข้อมูลรวมถึงไม่จำกัดรูปแบบ
                  ข่าวสาร การเชื่อมโยม คุณสมบัติ ภาพถ่าย กราฟฟิค เพลง เสียง
                  วิดีโอ ข้อความ แอปพลิเคชั่นที่ให้บริการบนระบบ Android และ iOS
                  หรือวัสดุอื่นๆ
                  ที่ทำให้ใช้งานได้ผ่านทางแพลตฟอร์มหรือบริการที่เกี่ยวข้องกับแพลตฟอร์ม
                  และรวมถึงคุณสมบัติใหม่ๆใดๆ ที่เพิ่มเติม
                  หรือเสริมไปยังแพลตฟอร์มให้อยู่ภายใต้เงื่อนไขการให้บริการนี้ด้วยเช่นกันซึ่งจัดไว้ให้และควบคุมโดย
                  MATCHDAY (รวมเรียกว่า เนื้อหา)
                  สัญญาขาย/บริการที่แท้จริงเป็นสัญญาระหว่างสถานบริการกีฬา
                  และผู้ใช้งาน (รวมเรียกว่า คุณ หรือ คู่สัญญา)
                  ที่แท้จริงโดยตรงเท่านั้น
                  คู่สัญญาของธุรกรรมดังกล่าวจะรับผิดชอบต่อสัญญาทั้งหมด บริการของ
                  MATCHDAY
                  เป็นเพียงตัวกลางหรือผู้ประสานงานให้เกิดการบริการที่แท้จริงขึ้น
                  ไม่ได้เป็นคู่สัญญาของสัญญาฉบับนั้น และจะไม่ยอมรับข้อผูกพันใดๆ
                  ที่เกี่ยวเนื่องกับสัญญาดังกล่าว MATCHDAY
                  ไม่สามารถรับประกันได้ว่าผู้ใช้จะทำธุรกรรมเสร็จสิ้นจริงๆ
                  คุณจะต้องอ่านและยอมรับเงื่อนไขและข้อกำหนดการให้บริการทั้งหมดที่เกี่ยวข้องและเชื่อมโยงกับการให้บริการนี้
                  และคุณจะต้องยินยอมและยอมรับการปฏิบัติงานตามนโยบายการป้องกันและดำเนินงานด้านข้อมูลส่วนบุคคลที่อธิบายไว้ในนโยบายความเป็นส่วนตัวของ
                  MATCHDAY เราขอสงวนสิทธิ์ในการดัดแปลง ปรับเปลี่ยน ระงับ หรือ
                  ยกเลิกแพลตฟอร์มนี้หรือบริการทั้งหมดหรือบางส่วนได้ตลอดเวลา
                  เราอาจออกบริการหรือคุณสมบัติบางอย่างในรุ่นเบต้า
                  ซึ่งอาจทำงานไม่ถูกต้องหรือในลักษณะเดียวกันกับที่รุ่นสุดท้ายอาจทำงาน
                  MATCHDAY จะไม่รับผิดชอบในกรณีดังกล่าว
                  และเราสามารถกำหนดขอบเขตหรือลักษณะการให้บริการบางอย่าง
                  หรือจำกัดการเข้าถึงบางส่วนหรือทั้งหมดโดยใช้ดุลยพินิจของเราแต่เพียงผู้เดียวโดยไม่ต้องแจ้งหรือรับผิด
                  เราขอสงวนสิทธิ์ในการปฏิเสธที่จะให้คุณเข้าถึงหรือรับบริการจากเรา
                  หรือปฏิเสธที่จะอนุญาตให้คุณเปิดแอคเค้าท์ของแพลตฟอร์มไม่ว่าด้วยเหตุผลใดก็ตาม
                  การเปิดแอคเค้าท์กับ MATCHDAY
                  ถือว่าคุณได้ให้การยอมรับและการยินยอมที่เพิกถอนไม่ได้ต่อข้อตกลงชุดนี้
                  รวมถึงข้อกำหนด และนโยบายเพิ่มเติมที่อ้างถึงในที่นี้
                  หากคุณไม่เห็นด้วยกับข้อตกลงนี้
                  กรุณางดใช้เข้าถึงหรือบริการเข้าถึงแพลตฟอร์มเรา
                  หากคุณยังไม่บรรลุนิติภาวะที่จะให้คำยินยอมตามกฎหมายที่เกี่ยวข้องในประเทศของคุณ
                  (นิติภาวะ) คุณจะต้องขออนุญาตจากบิดามารดา หรือผู้ปกครอง
                  หรือผู้แทนโดยชอบธรรมตามกฎหมายเพื่อเปิดบัญชี
                  และต้องได้รับความยินยอมกับข้อกำหนดหรือข้อตกลงนี้
                  หากผู้เยาว์ที่อยู่ภายใต้การปกครองที่กำลังสร้างบัญชีและเข้าใช้แพลตฟอร์มนี้
                  บิดามารดา หรือผู้ปกครอง
                  หรือผู้แทนโดยชอบธรรมจะต้องยอมรับข้อกำหนดหรือข้อตกลงนี้แทนผู้เยาว์
                  และคุณจะต้องรับผิดชอบต่อการใช้งานแอคเค้าท์จากเราทั้งหมด
                  ไม่ว่าแอคเค้าท์จะเปิดใช้อยู่แล้ว หรือสร้างในภายหลังก็ตาม
                </span>
              </p>

             

             

              <p class="c" style="margin-left: 20px; margin-right: 20px">
                <span style="font-size: 16px">
                  <b> 2. ความเป็นส่วนตัว</b> <br /><br />
                  2.1 MATCHDAY
                  ให้ความสำคัญกับความเป็นส่วนตัวและข้อมูลส่วนตัวของคุณ
                  ดังนั้นเราจึงจัดทำแนวทางปฏิบัติงานด้านนโยบายความเป็นส่วนตัวและการป้องกันข้อมูลส่วนตัวไว้อย่างละเอียด
                  โปรดอ่านนโยบายความเป็นส่วนตัวเพื่อให้เข้าใจว่า
                  เราสามารถเก็บรวบรวม ใช้ ประยุกต์ วิเคราะห์ เปิดเผย
                  และ/หรือดำเนินการกับเนื้อหาตามที่อธิบายในนโยบายความเป็นส่วนตัว
                  ซึ่งอาจมีการแก้ไขเป็นครั้งคราว
                  โปรดศึกษารายละเอียดเพิ่มเติมได้ที่ www.matchday.co.th/privacy
                  <br />
                  2.2 ผู้ใช้ที่ครอบครองข้อมูลส่วนบุคคลของคุณผ่านการใช้บริการ
                  ตกลงว่า (1)
                  จะปฏิบัติตามกฎหมายคุ้มครองข้อมูลส่วนบุคคลที่บังคับใช้ทั้งหมด
                  (2) อนุญาตให้ผู้ใช้
                  ซึ่งฝ่ายผู้รับได้เก็บรวบรวมข้อมูลส่วนบุคคลไว้ (ฝ่ายผู้เปิดเผย)
                  ลบข้อมูลของตนที่เก็บรวบรวมไว้ออกจากฐานข้อมูลของฝ่ายผู้รับ และ
                  (3)
                  อนุญาตให้ฝ่ายผู้เปิดเผยตรวจสอบว่าฝ่ายผู้รับมีการเก็บรวบรวมข้อมูลใดบ้างเกี่ยวกับตน
                  ในแต่ละกรณีของข้อ (2) และข้อ (3) ข้างต้น
                  ตามและเมื่อกฎหมายที่บังคับใช้กำหนดไว้ <br />
                  2.3 คุณสามารถคัดค้านการเก็บข้อมูลและการใช้ข้อมูล รวมถึงขอให้ลบ
                  หรือทำลาย หรือทำให้ข้อมูลที่ท่านได้กรอกและใช้งานผ่านแพลตฟอร์ม
                  หรือถูกบันทึกและจัดเก็บในแพลตฟอร์ม
                  เป็นข้อมูลที่ไม่สามารถระบุตัวตน ผ่านทางบริษัทที่เบอร์โทร
                  02-119-8414 อีเมล admin@matchday.co.th
                  โดยบริษัทจะดำเนินการลบ หรือทำลาย
                  หรือทำให้ข้อมูลดังกล่าวเป็นข้อมูลที่ไม่สามารถระบุตัวตน
                  ภายในระยะเวลา 90 วัน นับแต่วันที่ได้รับคำร้องขอ
                  แต่สำหรับข้อมูลที่ถูกส่งออกไปยังช่องทางต่าง ๆ แล้วนั้น
                  บริษัทจะพยายามอย่างถึงที่สุดเพื่อลบหรือทำลายข้อมูลดังกล่าว
                  หรือทำให้ข้อมูลดังกล่าวเป็นข้อมูลที่ไม่สามารถระบุตัวตนได้
                  <br />
                  2.4 คุณสามารถถอดถอนความยินยอมการเก็บรวบรวม ใช้
                  และเปิดเผยข้อมูลส่วนบุคคลได้โดยผ่านทางบริษัทที่ อีเมล
                  admin@matchday.co.th
                  ทั้งนี้การถอดถอนความยินยอมการเก็บรวบรวม ใช้
                  และเผยแพร่ข้อมูลส่วนบุคคลนี้
                  อาจมีผลให้ผู้หางานไม่สามารถใช้งานบัญชีผู้ใช้งานบน MATCHDAY
                  ได้อีก
                  <br />
                  2.5 เราขอเรียนให้คุณทราบว่าหลังจากลบบัญชีผู้ใช้งาน
                  หรือถอดถอนความยินยอมการเก็บรวบรวม ใช้
                  และเผยแพร่ข้อมูลส่วนบุคคล
                  เรายังคงมีความจำเป็นต้องเก็บรักษาข้อมูลของผู้ใช้ต่อเป็นระยะเวลา
                  90 วัน อันเนื่องมาจากข้อบังคับทางกฎหมาย
                </span>
              </p>

             

              <p class="c" style="margin-left: 20px; margin-right: 20px"></p>

              <p class="c" style="margin-left: 20px; margin-right: 20px">
                <span style="font-size: 16px">
                  <b>3. บัญชีและความปลอดภัย</b><br /><br />
                  3.1 การทำงานของ MATCHDAY จะต้องลงทะเบียนบัญชีโดยเลือก ID
                  ผู้ใช้ และเลือกรหัสผ่านที่ไม่ซ้ำกับ ID
                  ผู้ใช้พร้อมทั้งการให้ข้อมูลส่วนบุคคลบางอย่าง เช่น ชื่อ นามสกุล
                  อีเมล และ/หรือเชื่อมต่อผ่านโซเชียลมีเดีย
                  รวมถึงลงทะเบียนและยืนยันตัวตนผ่านหมายเลขโทรศัพท์
                  <br />3.2 คุณตกลงที่จะ (1)
                  เก็บรักษารหัสผ่านไว้เป็นความลับและใช้เฉพาะ ID
                  ผู้ใช้และรหัสผ่านของคุณเมื่อเข้าสู่ระบบ (2) แจ้ง MATCHDAY
                  ทันทีหากมีการใช้บัญชี ID ผู้ใช้
                  และ/หรือรหัสผ่านของคุณโดยไม่ได้รับอนุญาต (3)
                  ตรวจสอบให้แน่ใจว่าข้อมูลบัญชีของคุณถูกต้องและเป็นปัจจุบันอยู่เสมอ
                  คุณต้องรับผิดชอบอย่างเต็มที่ต่อกิจกรรมทั้งหมดที่เกิดขึ้นภายใต้
                  ID ผู้ใช้และบัญชีของคุณ
                  แม้ว่าคุณจะไม่ได้ทำกิจกรรมหรือทำการใช้งานดังกล่าวก็ตาม
                  MATCHDAY
                  จะไม่รับผิดต่อความสูญเสียหรือความเสียหายที่เกิดจากการใช้รหัสผ่านของคุณโดยไม่ได้อนุญาต
                  หรือการที่คุณไม่สามารถปฏิบัติตามความในส่วนนี้ <br />3.3
                  คุณเข้าใจและยินยอมให้เรายกเลิกบัญชีและ ID ผู้ใช้ของคุณได้ทันที
                  และลบเนื้อหาที่เกี่ยวข้องกับบัญชีหรือ ID
                  ผู้ใช้ของคุณออกจากแพลตฟอร์ม
                  ตามดุลยพินิจของเราแต่เพียงผู้เดียวโดยไม่ต้องแจ้งหรือรับผิดต่อคุณและบุคคลภายนอก
                  <br />3.4 คุณสามารถยกเลิกบัญชีของตนได้หากแจ้งให้ MATCHDAY
                  ทราบเป็นลายลักษณ์อักษร โดยการส่งคำขอมาที่ อีเมล
                  admin@matchday.co.th
                  นี้คุณยังคงต้องรับผิดชอบและรับผิดต่อธุรกรรมใดก็ตามที่ยังไม่เสร็จสิ้น
                  MATCHDAY ไม่ต้องรับผิด
                  และจะไม่รับผิดต่อความเสียหายที่เกิดขึ้นอันเนื่องมาจากการดำเนินการตามส่วนนี้
                  ผูัใช้จะสละสิทธิ์ในการเรียกร้องใดๆ
                  และทั้งหมดบนพื้นฐานการดำเนินการดังกล่าวโดย MATCHDAY <br />3.5
                  คุณสามารถใช้บริการและ/หรือเปิดบัญชีได้ต่อเมื่อคุณอาศัยอยู่ในประเทศที่ได้รับอนุญาตจากเรา
                  ซึ่งจะมีการปรับปรุงเป็นครั้งคราว
                </span>
              </p>

             

              <p class="c" style="margin-left: 20px; margin-right: 20px"></p>

              <p class="c" style="margin-left: 20px; margin-right: 20px">
                <span style="font-size: 16px">
                  <b>4. เงื่อนไขการใช้งาน</b><br /><br />
                  4.1 สิทธิการใช้งานแพลตฟอร์มนี้และบริการมีผลจนกว่าจะมีการยกเลิก
                  สิทธิการใช้งานนี้จะยกเลิกตามที่ระบุไว้ในเงื่อนไขการให้บริการ
                  หรือเมื่อคุณไม่ปฏิบัติตามข้อกำหนดหรือเงื่อนไขใดๆของเงื่อนไขการให้บริการนี้
                  ในกรณีดังกล่าว ไม่จำเป็นต้องแจ้งแก่
                  MATCHDAYเพื่อให้การยกเลิกดังกล่าวมีผล
                  <br />4.2 เพื่อความปลอดภัยหรือเหตุจำเป็นอื่นใด
                  เราอาจแก้ไขเปลี่ยนแปลงหรือเพิ่มเติมเงื่อนไขข้อตกลงการใช้บริการนี้
                  โดยมิต้องให้คำบอกกล่าวหรือแจ้งเตือนคุณเป็นการล่วงหน้า
                  เราแนะนำให้ผู้ใช้ตรวจสอบเงื่อนไขข้อตกลงการใช้บริการเป็นประจำระหว่างการใช้บริการ
                  อย่างไรก็ตามหากเราเปลี่ยนแปลงเงื่อนไขข้อตกลงการใช้บริการนี้อย่างมีนัยสำคัญ
                  เราจะแจ้งผู้ใช้โดยการส่งอีเมล
                  หรือแจ้งคุณโดยวิธีประกาศในเว็ปไซต์ และ/หรือ แพลตฟอร์ม
                  <br />4.3 สถานบริการกีฬาที่สมัครใช้บริการของเรา
                  ควรใช้ช่องทางแพลตฟอร์ม MATCHDAY เพียงช่องทางเดียวเท่านั้น
                  ในการจัดการตารางเวลาของสนามให้มีประสิทธิภาพ
                  และเพื่อประโยชน์ในการบริหารจัดการและการทำการตลาดของผู้รับสิทธิเอง
                  <br />4.4 คุณตกลงและยินยอมมอบข้อมูลในรูปแบบและประเภทต่าง ๆ
                  ตามแต่ที่เราร้องขอเท่าที่จำเป็นเพื่อประโยชน์ในการให้บริการสูงสุดของคุณ
                  <br />4.5 เราสงวนสิทธิ์ที่จะแสดงโฆษณาสำหรับคุณ
                  รวมถึงเพื่อเราเอง ให้คุณผ่านทางเว็บไซต์และแพลตฟอร์ม <br />4.6
                  คุณตกลงที่จะไม่: <br />&nbsp;&nbsp;&nbsp;(1) อัปโหลด โพสต์
                  ส่งต่อ หรือทำให้เนื้อหาที่ผิดกฎหมาย เป็นภัย คุกคาม ขู่เข็ญ
                  ล่วงละเมิด ทำให้ตื่นตกใจ ทำให้เป็นทุกข์ หลอกลวง
                  ทำให้เสื่อมเสีย หยาบคาย ลามก หมิ่นประมาท
                  ล่วงล้ำความเป็นส่วนตัวของผู้อื่น น่ารังเกียจ
                  เหยียดเชื้อชาติหรือชาติพันธุ์ ใช้งานได้
                  <br />&nbsp;&nbsp;&nbsp;(2)
                  ใช้บริการเพื่อปลอมแปลงเป็นบุคคลหรือนิติบุคคลอื่น
                  หรือแสดงข้อมูลเท็จเกี่ยวกับความเชื่อมโยงกับบุคคลหรือนิติบุคคลใด
                  <br />&nbsp;&nbsp;&nbsp;(3)
                  ปลอมแปลงหรือปรับเปลี่ยนรหัสประจำตัวเพื่อปิดบังแหล่งกำหนดของเนื้อหาที่ส่งต่อผ่านบริการ
                  <br />&nbsp;&nbsp;&nbsp;(4)
                  ลบประกาศเกี่ยวกับกรรมสิทธิ์ออกจากแพลตฟอร์ม
                  <br />&nbsp;&nbsp;&nbsp;(5) เป็นต้นเหตุ
                  อนุญาตหรือมอบอำนาจให้ทำการดัดแปลง สร้างงานอนุพันธ์
                  หรือแปลบริการโดยไม่ได้รับอนุญาตอย่างชัดแจ้งจาก MATCHDAY
                  <br />&nbsp;&nbsp;&nbsp;(6)
                  ใช้บริการเพื่อประโยชน์ของบุคคลภายนอกหรือในลักษณะที่ไม่ได้รับอนุญาตตามสิทธิการใช้งานที่ให้ไว้ในที่นี้
                  <br />&nbsp;&nbsp;&nbsp;(7) ใช้บริการเพื่อจุดประสงค์ในการฉ้อฉล
                  <br />&nbsp;&nbsp;&nbsp;(8) ปรับเปลี่ยนราคาของสินค้า/การบริการ
                  หรือแทรกแซงบัญชีรายชื่อสินค้า/การบริการของผู้ใช้รายอื่น
                  <br />&nbsp;&nbsp;&nbsp;(9) ดำเนินการใดๆ
                  ที่อาจบ่อนทำลายระบบให้ข้อคิดเห็นหรือระบบการให้คะแนน
                  <br />&nbsp;&nbsp;&nbsp;(10) พยายามแยกส่วนแฟ้ม
                  ทำวิศวกรรมย้อนกลับ ถอดประกอบหรือเจาะระบบบริการ
                  (หรือส่วนหนึ่งส่วนใดของบริการ)
                  หรือเอาชนะหรือทำลายเทคโนโลยีการเข้ารหัสหรือมาตรการความปลอดภัยที่
                  MATCHDAY นำมาใช้กับบริการและ/หรือข้อมูลที่ส่งต่อ ดำเนินการ
                  หรือจัดเก็บไว้โดย MATCHDAY <br />&nbsp;&nbsp;&nbsp;(11)
                  เก็บเกี่ยวหรือเก็บรวบรวมข้อมูลเกี่ยวกับเจ้าของบัญชีคนอื่น
                  รวมถึงไม่จำกัดเพียงข้อมูลส่วนบุคคลใดๆ
                  <br />&nbsp;&nbsp;&nbsp;(12) อัปโหลด อีเมล โพสต์ ส่งต่อ
                  หรือทำให้เนื้อหาที่คุณไม่มีสิทธิทำให้ใช้งานได้ภายใต้กฎหมายหรือภายใต้ความสัมพันธ์ตามสัญญาหรือการมอบอำนาจ
                  (เช่น ข้อมูลภายใน
                  ข้อมูลที่เป็นกรรมสิทธิ์และข้อมูลลับที่ได้ทราบหรือถูกเปิดเผยจากความสัมพันธ์ในการจ้างงานหรือภายใต้ข้อตกลงไม่เปิดเผยข้อมูล)
                  ใช้งานได้ <br />&nbsp;&nbsp;&nbsp;(13) อัปโหลด อีเมล โพสต์
                  ส่งต่อ หรือทำให้เนื้อหาที่ละเมิด สิทธิบัตร การออกแบบ
                  เครื่องหมายการค้า ความลับทางการค้า ลิขสิทธิ์
                  หรือทรัพย์สินทางปัญญาของผู้อื่น <br />&nbsp;&nbsp;&nbsp;(14)
                  อัปโหลด อีเมล โพสต์ ส่งต่อ
                  หรือทำให้โฆษณาที่ไม่ได้ร้องขอหรือได้รับอนุญาต
                  สื่อประชาสัมพันธ์ เมลขยะ สแปม จดหมายลูกโซ่ ธุรกิจแบบพีระมิด
                  หรือรูปแบบการเชิญชวนอื่นใดที่ไม่ได้รับอนุญาต ใช้งานได้
                  <br />&nbsp;&nbsp;&nbsp;(15) อัปโหลด อีเมล โพสต์ ส่งต่อ
                  หรือทำให้วัสดุอื่นๆ ที่มีไวรัสซอฟต์แวร์ หนอน ม้าโทรจัน
                  หรือรหัสคอมพิวเตอร์ รูทีน
                  ไฟล์หรือโปรแกรมที่ออกแบบมาเพื่อแทรกแซงทั้งโดยตรงและโดยอ้อมต่อ
                  ปรับเปลี่ยน ขัดขวาง ทำลาย
                  หรือจำกัดฟังก์ชั่นการทำงานของซอฟต์แวร์คอมพิวเตอร์หรือฮาร์ดแวร์ใดๆ
                  หรือข้อมูลหรืออุปกรณ์โทรคมนาคม ใช้งานได้
                  <br />&nbsp;&nbsp;&nbsp;(16) แทรกแซง ปรับเปลี่ยน
                  หรือขัดขวางบริการหรือเซิร์ฟเวอร์หรือเครือข่ายที่เชื่อมต่อกับบริการหรือการใช้งานของผู้ใช้คนอื่นและการเพลิดเพลินกับบริการ
                  หรือฝ่าฝืนข้อกำหนด กระบวนการ นโยบาย
                  หรือข้อบังคับของเครือข่ายที่เชื่อมต่อกับแพลตฟอร์ม
                  <br />&nbsp;&nbsp;&nbsp;(17)
                  ดำเนินการหรือเข้าร่วมในการกระทำที่อาจส่งผลเสียหายทั้งทางตรงหรือทางอ้อม
                  ปิดใช้งาน สร้างภาระหนัก
                  หรือทำให้บริการหรือเซิร์ฟเวอร์หรือเครือข่ายที่เชื่อมต่อกับบริการมีข้อบกพร่อง
                  <br />&nbsp;&nbsp;&nbsp;(18)
                  ใช้บริการเพื่อเจตนาหรือไม่เจตนาฝ่าฝืนกฎหมาย กฎระเบียบ
                  ประมวลกฎหมาย คำสั่ง แนวทางปฏิบัติ นโยบาย
                  หรือข้อบังคับของท้องถิ่น ของรัฐ ประเทศ
                  หรือระหว่างประเทศที่เกี่ยวข้อง รวมถึง แต่ไม่จำกัดเพียง
                  กฎหมายและข้อกำหนด (ไม่ว่ามีอำนาจทางกฎหมายหรือไม่)
                  เกี่ยวกับการป้องกันการฟอกเงินหรือการต่อต้านการก่อการร้าย
                  <br />&nbsp;&nbsp;&nbsp;(19) ใช้บริการเพื่อฝ่าฝืนหรือหลบเลี่ยง
                  การคว่ำบาตรหรือการห้ามค้าขายที่ดำเนินการหรือบังคับใช้โดยสำนักควบคุมสินทรัพย์ต่างประเทศของกระทรวงการคลังสหรัฐฯ
                  คณะมนตรีความมั่นคงแห่งสหประชาชาติ
                  กระทรวงการคลังสหภาพยุโรปหรือสหราชอาณาจักร
                  <br />&nbsp;&nbsp;&nbsp;(20)
                  ใช้บริการเพื่อฝ่าฝืนความเป็นส่วนตัวของผู้อื่น หรือ ติดตาม
                  หรือก่อกวนผู้อื่น หรือก่อให้เกิดการทะเลาะวิวาท
                  ซึ่งนำมาสู่ความเสียหายต่อทรัพย์สิน หรือ
                  ร่างกายทั้งทางตรงและทางอ้อมของผู้อื่น
                  <br />&nbsp;&nbsp;&nbsp;(21) ละเมิดสิทธิต่างๆ ของ MATCHDAY
                  ซึ่งรวมถึงสิทธิในทรัพย์สินทางปัญญาและการลวงขายในเรื่องนั้นๆ
                  ด้วย <br />&nbsp;&nbsp;&nbsp;(22)
                  ใช้บริการเพื่อเก็บรวบรวมหรือจัดเก็บข้อมูลส่วนบุคคลเกี่ยวกับผู้ใช้คนอื่นที่เกี่ยวกับการกระทำและกิจกรรมต้องห้ามที่ระบุไว้ด้านบน
                  และ/หรือ <br />&nbsp;&nbsp;&nbsp;(23)
                  ลงรายการที่ละเมิดลิขสิทธิ์ เครื่องหมายการค้า
                  หรือสิทธิในทรัพย์สินทางปัญหาของบุคคลภายนอกหรือ
                  ใช้บริการในลักษณะที่จะละเมิดสิทธิ์ในทรัพย์สินทางปัญญาของผู้อื่น
                  <br />&nbsp;&nbsp;&nbsp;(24)
                  โอนสิทธิการใช้บัญชีที่ใช้บริการซอฟต์แวร์ของ MATCHDAY
                  ไปให้บุคคลอื่น <br />4.7 คุณเข้าใจว่าเนื้อหาทั้งหมด
                  ไม่ว่าโพสต์ต่อสาธารณะหรือส่งต่อแบบเป็นส่วนตัว
                  เป็นความรับผิดชอบของผู้ที่เป็นต้นกำเนิดของเนื้อหาดังกล่าวแต่เพียงผู้เดียว
                  คุณต้องรับผิดชอบต่อเนื้อหาทั้งหมดที่คุณอัปโหลด โพสต์ อีเมล
                  ส่งต่อ หรือทำให้ใช้งานได้ทางแพลตฟอร์มแต่เพียงผู้เดียว MATCHDAY
                  ไม่ควบคุมและตรวจสอบเนื้อหาที่โพสต์บนแพลตฟอร์ม
                  และดังนั้นจึงไม่รับประกันความถูกต้อง ความสมบูรณ์
                  หรือคุณภาพของเนื้อหาดังกล่าว
                  คุณเข้าใจว่าในการใช้แพลตฟอร์มคุณอาจพบเจอกับเนื้อหาที่อาจถือได้ว่าสร้างความขุ่นเคืองใจ
                  ลามกอนาจารหรือน่ารังเกียจ
                  ภายใต้ขอบเขตสูงสุดที่กฎหมายที่เกี่ยวข้องอนุญาตไว้
                  ไม่ว่าในกรณีใดก็ตาม MATCHDAY
                  จะไม่รับผิดไม่ว่าในทางใดต่อเนื้อหา รวมถึงแต่ไม่จำกัดเพียง
                  ข้อผิดพลาด หรือการละเว้นเนื้อหา
                  หรือการสูญเสียหรือความเสียหายชนิดใดก็ตามที่เกิดขึ้นอันเป็นผลมาจากการใช้
                  หรือการพึ่งพาเนื้อหาที่โพสต์ อีเมล ส่งต่อ
                  หรือทำให้ใช้งานได้บนแพลตฟอร์ม <br />4.8 คุณรับทราบว่า MATCHDAY
                  อาจหรืออาจไม่คัดกรองเนื้อหาล่วงหน้า แต่ MATCHDAY
                  และผู้ที่ได้รับการแต่งตั้งจาก MATCHDAY จะมีสิทธิ
                  (แต่ไม่ใช่ข้อผูกพัน)
                  ตามดุลยพินิจแต่เพียงผู้เดียวของตนในการคัดกรองล่วงหน้า ปฏิเสธ
                  ลบหรือย้ายเนื้อหาใดๆ ที่ใช้งานได้บนแพลตฟอร์ม
                  โดยไม่เป็นการจำกัดสิ่งที่กล่าวไปก่อนหน้า MATCHDAY
                  และผู้ที่ได้รับการแต่งตั้งจาก MATCHDAY มีสิทธิที่จะลบเนื้อหา
                  (1) ที่ละเมิดเงื่อนไขการให้บริการ (2)
                  ถ้าเราได้รับคำร้องเรียนจากผู้ใช้คนอื่น (3)
                  ถ้าเราได้รับแจ้งเกี่ยวกับการละเมิดทรัพย์สินทางปัญญาหรือคำสั่งทางกฎหมายให้ลบออก
                  หรือ (4) ถ้าเนื้อหาดังกล่าวน่ารังเกียจ หรือหยาบคาย
                  เรายังอาจปิดกั้นการส่งการสื่อสาร (รวมถึงแต่ไม่จำกัดเพียง
                  การอัปเดตสถานะ การโพสต์ ข้อความ และ/หรือการสนทนา)
                  ไปยังหรือจากบริการ
                  อันเป็นส่วนหนึ่งของความพยายามของเราที่จะปกป้องบริการหรือผู้ใช้ของเรา
                  หรือบังคับใช้บทบัญญัติของข้อกำหนดและเงื่อนไขเหล่านี้
                  คุณตกลงว่าคุณจะต้องประเมิน
                  และแบกรับความเสี่ยงทั้งหมดที่เกี่ยวกับการใช้เนื้อหา
                  รวมถึงแต่ไม่จำกัดเพียง การพึ่งพาความถูกต้อง ความครบถ้วน
                  หรือประโยชน์ของเนื้อหาดังกล่าว เกี่ยวกับเรื่องนี้
                  คุณรับทราบว่าคุณไม่ได้ และ
                  (ภายใต้ขอบเขตสูงสุดที่กฎหมายที่เกี่ยวข้องอนุญาตไว้)
                  ไม่สามารถพึ่งพาเนื้อหาที่ MATCHDAY สร้างขึ้น หรือส่งมายัง
                  MATCHDAY รวมถึงแต่ไม่จำกัดเพียง ข้อมูลในกระดานสนทนาของ
                  MATCHDAY และในส่วนอื่นๆ ทั้งหมดของแพลตฟอร์ม <br />4.9
                  คุณรับทราบ ยินยอม และตกลงว่า MATCHDAY อาจเข้าถึง รักษา
                  และเปิดเผยข้อมูลบัญชีของคุณและเนื้อหาหากกฎหมายกำหนดไว้เช่นนั้น
                  หรือตามคำสั่งศาลหรือโดยหน่วยงานรัฐหรือหน่วยงานทางกฎหมายที่มีเขตอำนาจเหนือ
                  MATCHDAY
                  หรือโดยการเชื่อโดยสุจริตว่าการเข้าถึงเพื่อรักษาหรือเปิดเผยนั้นมีความจำเป็นตามสมควรเพื่อ:
                  (1) ปฏิบัติตามขั้นตอนทางกฎหมาย (2)
                  บังคับใช้เงื่อนไขการให้บริการนี้ (3)
                  ตอบสนองต่อข้อเรียกร้องที่ว่าเนื้อหาใดละเมิดสิทธิของบุคคลภายนอก
                  (4) ตอบสนองต่อคำขอการบริการผู้ใช้งานของคุณ หรือ (5)
                  ปกป้องสิทธิ ทรัพย์สินหรือความปลอดภัยส่วนบุคคลของ MATCHDAY
                  ผู้ใช้และ/หรือประชาชนทั่วไป
                </span>
              </p>

             

              <p class="c" style="margin-left: 20px; margin-right: 20px"></p>

              <p class="c" style="margin-left: 20px; margin-right: 20px">
                <span style="font-size: 16px">
                  <b
                    >5.
                    การนำข้อมูลที่มีอยู่ปัจจุบันของสถานบริการกีฬาขึ้นสู่แพลตฟอร์ม</b
                  ><br /><br />
                  5.1
                  บริษัทให้ความสำคัญแก่ความต่อเนื่องของการให้บริการของสถานบริการกีฬาเอง
                  เราจึงจัดให้มีการบริการนำข้อมูลพื้นฐานการบริการของสถานบริการกีฬาขึ้นสู่แพลตฟอร์ม
                  <br />5.2 เพื่อประโยชน์สูงสุดของคุณ
                  สถานบริการกีฬาอาจให้ข้อมูลส่วนบุคคลที่เกี่ยวข้องกับบุคคลอื่น
                  แต่จำกัดวัตถุประสงค์เพื่อการใช้บริการขั้นพื้นฐานของแพลตฟอร์มเพื่อประโยชน์สูงสุดของสถานบริการกีฬาเอง
                  เช่น <br />&nbsp;&nbsp;&nbsp;(1) ข้อความ ภาพวาด กราฟฟิค
                  ภาพถ่าย ข้อมูล เครื่องหมายที่จำเป็นต่อการบริการขั้นพื้นฐาน
                  <br />&nbsp;&nbsp;&nbsp;(2) ชื่อ นามสกุล
                  เบอร์โทรศัพท์ของคุณที่แสดงความจำนงค์จองวันและเวลาเพื่อใช้บริการสถานบริการกีฬาหนึ่งเป็นระยะเวลาต่อเนื่อง
                  (Long-term booking) หากสถานบริการกีฬาให้ข้อมูลส่วนนี้แก่เรา
                  คุณรับรองและรับประกันว่าคุณได้รับความยินยอมให้มีการเก็บรวบรวม
                  ใช้ และเปิดเผยข้อมูลส่วนบุคคลของผู้ใช้คุณอื่นแล้ว
                </span>
              </p>

             

              <p class="c" style="margin-left: 20px; margin-right: 20px"></p>

              <p class="c" style="margin-left: 20px; margin-right: 20px">
                <span style="font-size: 16px">
                  <b
                    >6. การทำรายการการจอง
                    เปลี่ยนแปลงรายการและยกเลิกเวลากับสถานบริการกีฬา</b
                  ><br /><br />
                  6.1
                  คุณสามารถใช้บริการแพลตฟอร์มเพื่อจองเวลาสำหรับการเล่นกีฬาได้
                  โดยจะต้องสมัครบัญชีกับแพลตฟอร์ม และรับทราบให้เรา ดังนี้
                  <br />&nbsp;&nbsp;&nbsp;(1) เราจะใช้ จัดเก็บ รวบรวม และเปิดเผย
                  ชื่อ นามสกุล
                  และเบอร์โทรศัพท์ข้อมูลที่คุณลงทะเบียนบัญชีในหัวข้อ 3.1
                  เพื่อยืนยัน ติดต่อ และติดตามการจองของผู้ใช้งาน
                  ให้แก่ผู้ให้บริการสถานที่เล่นกีฬาภายในแพลตฟอร์ม
                  <br />&nbsp;&nbsp;&nbsp;(2)
                  เราอาจติดต่อผู้ใช้งานตามการจองของผู้ใช้งานตามข้อมูลที่ระบุไว้ในบัญชี
                  เพื่อติดตามการจองของผู้ใช้งาน <br />&nbsp;&nbsp;&nbsp;(3)
                  เราจะเก็บข้อมูลการจองของคุณเป็นระยะเวลาไม่เกิน 1 ปี
                  หลังจากนั้นเราจะทำให้ข้อมูลดังกล่าวไม่สามารถระบุตัวตนได้
                </span>
              </p>

             

              <p class="c" style="margin-left: 20px; margin-right: 20px"></p>

              <p class="c" style="margin-left: 20px; margin-right: 20px">
                <span style="font-size: 16px">
                  <b>7. การค้นหาเพื่อนร่วมเล่นกีฬา</b><br /><br />
                  7.1
                  แพลตฟอร์มมีบริการให้ผู้ใช้งานสามารถหาเพื่อนร่วมเล่นกีฬาตามวันเวลาที่คุณต้องการได้
                  โดยคุณสามารถระบุความต้องการดังกล่าวบนแอปพลิเคชั่นก่อนยืนยันการจองสถานที่เล่นกีฬาที่คุณต้องการ
                  เพื่อความปลอดภัยของการใช้งานบริการนี้ แพลตฟอร์มจำเป็นที่จะเก็บ
                  ใช้ รวบรวม
                  และเปิดเผยรูปภาพโปรไฟล์ของคุณเพื่อป้องกันการกระทำผิดกฎหมาย
                  เป็นภัย คุกคาม ขู่เข็ญ ล่วงละเมิด ทำให้ตื่นตกใจ ทำให้เป็นทุกข์
                  หลอกลวง ทำให้เสื่อมเสีย หยาบคาย ลามก หมิ่นประมาท
                  ต่อผู้อื่นในการใช้แพลตฟอร์มนี้
                  <br />7.2
                  คุณรับทราบและยินยอมให้สิทธิ์แก่เราในการแสดงชื่อผู้ใช้งาน
                  รูปโปรไฟล์ และข้อมูลที่เกี่ยวกับการดำเนินการ (เช่น
                  จำนวนครั้งที่เล่นกีฬา) หรือความสัมพันธ์ของคุณ (เช่น
                  การติดตามผู้ใช้งานอื่น) ถัดจากหรือร่วมกับบัญชีผู้ใช้ โฆษณา
                  ข้อเสนอ และเนื้อหาที่ได้รับการสนับสนุนอื่นๆ ที่คุณติดตาม
                  โดยคุณไม่ได้รับค่าตอบแทนใดๆ ตัวอย่างเช่น
                  เราอาจแสดงว่าคุณคลิกดูเนื้อหาที่ได้รับการสนับสนุนซึ่งสร้างขึ้นโดยสถานบริการด้านกีฬาที่จ่ายเงินให้เราเพื่อแสดงโฆษณาบนแพลตฟอร์ม
                  เฉพาะผู้ที่ได้รับสิทธิ์อนุญาตให้ดูเนื้อหาหรือการติดตามดังกล่าวเท่านั้น
                </span>
              </p>

             

              <p class="c" style="margin-left: 20px; margin-right: 20px">
                <span style="font-size: 16px">
                  <b
                    >8. การจัดทำข้อมูลแผงควบคุมเชิงวิเคราะห์ (Analytics
                    Dashboard)</b
                  ><br /><br />
                  8.1
                  สำหรับสถานบริการกีฬาที่ได้รับสิทธิโดยไม่จำกัดแต่เพียงผู้เดียวให้เข้าถึงการบริการแพลตฟอร์ม
                  จะได้สิทธิเข้าถึงการบริการใช้งานแผงควบคุมเชิงวิเคราะห์
                  (Analytics Dashboard)
                  ที่แสดงถึงกลุ่มผู้ใช้งานเป้าหมายเชิงประชากรศาสตร์และการตลาดดิจิทัลของสถานบริการกีฬาตนเอง
                  <br />
                  8.2 แพลตฟอร์มจะเก็บ รวบรวม ใช้
                  และเปิดเผยอย่างที่ไม่สามารถระบุตัวตนข้อมูลเชิงประชากรศาสตร์
                  (เพศและอายุ) ของผู้ใช้งาน
                  เพื่อใช้ในการบริการดังกล่าวแก่สถานบริการกีฬานั้นๆ
                </span>
              </p>

             

              <p class="c" style="margin-left: 20px; margin-right: 20px"></p>

              <p class="c" style="margin-left: 20px; margin-right: 20px">
                <span style="font-size: 16px">
                  <b>9. การใช้โลเคชั่นเพื่อนำเสนอสถานบริการกีฬาใกล้คุณ</b
                  ><br /><br />
                  9.1 เราจะเก็บ รวบรวม
                  ใช้ข้อมูลตำแหน่งของคุณจากโทรศัพท์มือถือของคุณ
                  เพื่อนำเสนอสถานบริการกีฬาใกล้คุณ
                  ทั้งนี้คุณสามารถเลือกได้ว่าจะให้หรือไม่ให้ความยินยอมกับเราในการใช้ข้อมูลชุดนี้
                </span>
              </p>

             

              <p class="c" style="margin-left: 20px; margin-right: 20px"></p>

              <p class="c" style="margin-left: 20px; margin-right: 20px">
                <span style="font-size: 16px">
                  <b>10. การประชาสัมพันธ์และการเก็บข้อมูลเพื่อใช้ในการตลาด</b
                  ><br /><br />
                  10.1 เราจะเก็บ รวบรวม ใช้ข้อมูลส่วนบุคคลของคุณเพื่อทำการตลาด
                  เช่น แต่ไม่จำกัดเพียง อีเมล โฆษณาบนแพลตฟอร์ม
                  เพื่อประมวลข้อมูลทางการตลาดบนพื้นฐานการให้บริการและเพื่อให้คุณได้รับประโยชน์สูงสุด
                  <br />10.2
                  คุณสามารถเลือกได้ว่าจะให้หรือไม่ให้ความยินยอมกับเราในการใช้ข้อมูลส่วนบุคคลของคุณเพื่อวัตถุประสงค์ทางการตลาด
                </span>
              </p>

             

              <p class="c" style="margin-left: 20px; margin-right: 20px">
                <span style="font-size: 16px">
                  <b> 11. การละเมิดเงื่อนไขการให้บริการของเรา</b
                  ><br /><br />11.1
                  การละเมิดข้อกำหนดและเงื่อนการให้บริการนี้อาจส่งผลให้มีการดำเนินการหลายประการ
                  รวมถึงแต่ไม่จำกัดเพียง สิ่งใดสิ่งหนึ่งหรือทั้งหมดต่อไปนี้
                  <br />&nbsp;&nbsp;&nbsp;(1) บัญชีรายชื่อถูกลบ
                  <br />&nbsp;&nbsp;&nbsp;(2)
                  ถูกกำหนดขีดจำกัดสำหรับสิทธิพิเศษของบัญชี
                  <br />&nbsp;&nbsp;&nbsp;(3) บัญชีถูกระงับและยกเลิกในเวลาต่อมา
                  <br />&nbsp;&nbsp;&nbsp;(4) ถูกดำเนินคดีอาญา
                  <br />&nbsp;&nbsp;&nbsp;(5) ถูกดำเนินคดีทางแพ่ง
                  รวมถึงแต่ไม่จำกัดเพียงการเรียกร้องค่าเสียหายและ/หรือวิธีการชั่วคราว
                  ก่อนพิพากษาหรือคำสั่งห้ามของศาล <br />11.2
                  หากคุณเชื่อว่าผู้ใช้บนแพลตฟอร์มของเราละเมิดเงื่อนไขการให้บริการนี้
                  โปรดติดต่อเราที่ admin@matchday.co.th
                </span>
              </p>

             

              <p class="c" style="margin-left: 20px; margin-right: 20px"></p>

              <p class="c" style="margin-left: 20px; margin-right: 20px">
                <span style="font-size: 16px">
                  <b>12. การรายงานการละเมิดสิทธิในทรัพย์สินทางปัญญา</b
                  ><br /><br />
                  12.1 MATCHDAY
                  ดำเนินธุรกิจแพลตฟอร์มสำหรับการบริหารและจองสถานบริการกีฬาเพื่อเล่นกีฬา
                  ผู้ใช้เป็นปัจเจกบุคคลหรือธุรกิจที่เป็นอิสระและไม่เกี่ยวกับ
                  MATCHDAY ไม่ว่าในทางใด MATCHDAY
                  มิใช่ตัวแทนหรือผู้แทนของผู้ใช้และมิได้ครอบครองและ/หรือเป็นเจ้าของสินค้า/บริการใดๆ
                  ที่ลงรายการไว้บนแพลตฟอร์ม
                  <br />
                  12.2 หากคุณเป็นเจ้าของสิทธิในทรัพย์สินทางปัญญา (เจ้าของ IPR)
                  หรือตัวแทนที่ได้รับอนุญาตอย่างถูกต้องตามกฎหมายจากเจ้าของ IPR
                  (ตัวแทน IPR)
                  และคุณเชื่อว่าสิทธิของคุณหรือสิทธิของตัวการของคุณได้ถูกละเมิด
                  โปรดทำหนังสือแจ้งให้เราทราบเป็นลายลักษณ์อักษรโดยส่งทางอีเมลมาที่
                  admin@matchday.co.th และส่งเอกสารให้ตามที่ระบุด้านล่าง
                  (6.3) แก่เราเพื่อสนับสนุนการเรียกร้องสิทธิของคุณ
                  โปรดให้เวลาเราประมวลข้อมูลที่ได้จัดส่งมาให้เราและจะตอบข้อร้องเรียนของคุณโดยเร็วที่สุดเท่าที่สามารถดำเนินการได้
                  <br />
                  12.3 ข้อเรียกร้องภายใต้ส่วนที่ 13
                  นี้ต้องจัดส่งให้ตามแบบฟอร์มที่กำหนดโดย MATCHDAY
                  ซึ่งอาจจะมีการปรับปรุงเป็นครั้งคราว
                  และอย่างน้อยต้องดำเนินการดังต่อไปนี้ด้วย (1)
                  ลงลายมือชื่อจริงหรือลายเซ็นอิเล็กทรอนิกส์ของเจ้าของ IPR หรือ
                  ตัวแทน IPR (เรียกรวมกันว่า ผู้ให้ข้อมูล) (2)
                  คำอธิบายถึงประเภทและลักษณะของสิทธิในทรัพย์สินทางปัญญาที่ถูกกล่าวหาว่าถูกละเมิดและหลักฐานแห่งสิทธิต่างๆ
                  (3) เอกสารแสดงความเป็นเจ้าของสิทธิในทรพย์สินทางปัญญา (4)
                  รายละเอียดของรายการที่มีการละเมิดตามที่ได้กล่าวหา (5)
                  ข้อมูลเพียงพอที่จะช่วยให้ MATCHDAY ติดต่อผู้ให้ข้อมูลได้ เช่น
                  ที่อยู่ทางไปรษณีย์ หมายเลขโทรศัพท์ และที่อยู่อีเมล (6)
                  ถ้อยแถลงจากผู้ให้ข้อมูลที่ว่าข้อร้องเรียนนี้ได้ยื่นเข้ามาด้วยความเชื่อโดยสุจริตและการใช้ทรัพย์สินทางปัญญาที่ระบุโดยผู้ให้ข้อมูล
                  ไม่ได้รับอนุญาตจากเจ้าของ IPR
                  หรือตามที่กฎหมายบัญญัติให้ดำเนินการได้ (6)
                  ถ้อยแถลงจากผู้ให้ข้อมูลที่ว่าข้อมูลในหนังสือบอกกล่าวนั้นถูกต้อง
                  ทั้งนี้
                  จะต้องรับผิดชดใช้ค่าเสียหายที่เราอาจได้รับอันเป็นผลมาจากข้อมูลที่ผู้ให้ข้อมูลได้จัดเตรียมมาให้
                  นอกจากนี้
                  ผู้ให้ข้อมูลรับรองว่าตนมีสิทธิอย่างเหมาะสมหรือได้รับอนุญาตให้ดำเนินการในนามของเจ้าของ
                  IPR ในการร้องเรียนดังกล่าว
                </span>
              </p>

             

              <p class="c" style="margin-left: 20px; margin-right: 20px"></p>

              <p class="c" style="margin-left: 20px; margin-right: 20px">
                <span style="font-size: 16px">
                  <b>13. ค่าธรรมเนียม</b><br /><br />
                  13.1 ผู้ใช้ตกลงจะชำระค่าธรรมเนียมการใช้บริการซอฟต์แวร์ของ
                  MATCHDAY ให้แก่ MATCHDAY ตามที่ MATCHDAY กำหนด
                  โดยชำระผ่านช่องทางเรากำหนด
                  <br />13.2 ค่าธรรมเนียมจะไม่รวมถึง GST/VAT
                  และภาษีที่เกี่ยวข้องอื่นๆ เว้นแต่ตกลงกันไว้เป็นอย่างอื่น
                  ผู้ใช้จะรับผิดชอบค่าภาษีเหล่านั้นเอง ผู้ใช้รับทราบและตกลงว่า
                  MATCHDAY สามารถหักค่าธรรมเนียมและภาษีที่เกี่ยวข้อง และ
                  MATCHDAY
                  จะออกใบเสร็จรับเงินหรือใบกำกับภาษีสำหรับค่าธรรมเนียมและภาษีที่ผู้ขายชำระไว้เมื่อร้องขอ
                  <br />13.3
                  ภายหลังจากผู้ใช้ได้ชำระค่าธรรมเนียมการใช้บริการซอฟต์แวร์ให้แก่
                  MATCHDAY แล้ว MATCHDAY
                  ขอสงวนสิทธิ์ไม่คืนเงินค่าธรรมเนียมดังกล่าวทุกกรณี
                </span>
              </p>

             

              <p class="c" style="margin-left: 20px; margin-right: 20px"></p>

              <p class="c" style="margin-left: 20px; margin-right: 20px">
                <span style="font-size: 16px">
                  <b>14. ข้อพิพาท</b><br /><br />
                  MATCHDAY ส่งเสริมให้ผู้ใช้สื่อสารกันผ่านช่องทางที่เรา
                  จัดเตรียมไว้ให้เท่านั้นในกรณีที่เกิดปัญหาในการทำธุรกรรม แม้
                  MATCHDAY
                  ให้บริการแพลตฟอร์มสำหรับการบริหารและจองสถานบริการกีฬาเพื่อเล่นกีฬา
                  ผู้ใช้ต้องคำนึงไว้เสมอว่า MATCHDAY เป็นเพียงผู้ประสานงานหลัก
                  มิได้เป็นเจ้าของของสินค้า/การบริการใดๆ บนแพลตฟอร์ม นอกจากนี้
                  ผู้ใช้ยังสามารถนำเรื่องขึ้นร้องเรียนต่อศาลยุติธรรมในเขตอำนาจศาลของตนเพื่อยุติข้อพิพาทอันเกิดจากการทำธุรกรรม
                </span>
              </p>

             

              <p class="c" style="margin-left: 20px; margin-right: 20px">
                <span style="font-size: 16px">
                  <b>15. การปฏิเสธความรับผิดชอบ</b><br /><br />
                  15.1 บริการนี้จัดไว้ให้ตามสภาพจริงโดยไม่มีการรับประกัน
                  การอ้างสิทธิ หรือการรับรองโดย MATCHDAY ไม่ว่าในลักษณะใด
                  ทั้งแบบชัดแจ้ง โดยนัย หรือแบบบังคับ อันเกี่ยวเนื่องกับบริการ
                  รวมถึงแต่ไม่จำกัดเพียง การรับประกันคุณภาพ การดำเนินงาน
                  การไม่ละเมิดสิทธิ ความสามารถในการทำการค้า
                  หรือความเหมาะสมสำหรับวัตถุประสงค์เฉพาะ
                  รวมทั้งไม่มีการรับประกันที่เกิดขึ้นระหว่างการติดต่อ
                  การดำเนินงาน หรือการใช้เพื่อการค้า
                  โดยไม่เป็นการจำกัดสิ่งที่กล่าวไปก่อนหน้านี้
                  และภายใต้ขอบเขตสูงสุดที่กฎหมายที่เกี่ยวข้องอนุญาตไว้
                  MATCHDAYไม่รับประกันว่า บริการ แพลตฟอร์มนี้
                  หรือการทำงานในแพลตฟอร์มจะพร้อมใช้งานได้ เข้าถึงได้
                  ไม่หยุดชะงัก ทันต่อเวลา ปลอดภัย ถูกต้อง ครบถ้วน
                  หรือปราศจากข้อผิดพลาด ว่าข้อบกพร่อง หากมี จะได้รับการแก้ไข
                  หรือว่า
                  แพลตฟอร์มนี้และ/หรือเซิร์ฟเวอร์ของแพลตฟอร์มจะใช้งานได้โดยปราศจากไวรัส
                  นาฬิกา ระบบตั้งเวลา ตัวนับ หนอน ล็อคซอฟต์แวร์ อุปกรณ์สอดแนม
                  ม้าโทรจัน เราติ้ง ประตูกับดัก ระเบิดเวลา หรือรหัส คำสั่ง
                  โปรแกรม หรือส่วนประกอบที่เป็นอันตรายอื่นใด <br />
                  15.2
                  คุณรับทราบว่าความเสี่ยงทั้งหมดที่เกิดขึ้นจากการใช้หรือการดำเนินงานของแพลตฟอร์มนี้และ/หรือบริการตกเป็นของคุณ
                  ภายใต้ขอบเขตสูงสุดที่กฎหมายที่เกี่ยวข้องอนุญาตไว้ <br />
                  15.3 MATCHDAY ไม่ได้ควบคุมและ
                  ภายใต้ขอบเขตสูงสุดที่กฎหมายที่เกี่ยวข้องอนุญาตไว้
                  ไม่รับประกันหรือรับผิดชอบต่อ: (1) ความเหมาะสมเพื่อวัตถุประสงค์
                  การมีอยู่ คุณภาพ
                  ความปลอดภัยหรือความชอบด้วยกฎหมายของสินค้าและ/หรือบริการที่วางจำหน่ายผ่านทางบริการ
                  หรือ (2)
                  ความสามารถของผู้ให้บริการในการขายสินค้า/บริการหรือของผู้ใช้งานในการชำระค่าสินค้า/บริการ
                  หากมีข้อพิพาทที่เกี่ยวกับผู้ใช้ตั้งแต่หนึ่งคนขึ้นไป
                  ผู้ใช้ดังกล่าวตกลงที่จะยุติข้อพิพาทระหว่างกันโดยตรง
                  และภายใต้ขอบเขตสูงสุดที่กฎหมายที่เกี่ยวข้องอนุญาตไว้
                  ปลดเปลื้อง MATCHDAY จากการอ้างสิทธิ การเรียกร้อง
                  และความเสียหายใดๆ และทั้งหมด
                  อันเกิดจากหรือเกี่ยวเนื่องกับข้อพิพาทดังกล่าว <br />
                  15.4 MATCHDAY
                  ไม่ต้องรับผิดในกรณีที่ข้อมูลที่คุณบรรจุไว้บนซอฟต์แวร์ของ
                  MATCHDAY สูญหาย เสียหายทั้งสิ้น
                </span>
              </p>

             

              <p class="c" style="margin-left: 20px; margin-right: 20px">
                <span style="font-size: 16px">
                  <b>16. ข้อยกเว้นและข้อจำกัดการรับผิด</b><br /><br />
                  16.1 ภายใต้ขอบเขตสูงสุดที่กฎหมายที่เกี่ยวข้องอนุญาตไว้
                  ไม่ว่าในสถานการณ์ใดก็ตาม MATCHDAY จะไม่รับผิดไม่ว่าตามสัญญา
                  การรับประกัน การละเมิด (รวมถึง แต่ไม่จำกัดเพียง
                  ความประมาทเลินเล่อ (ไม่ว่าในเชิงรุก เชิงรับ หรือถูกใส่ความ)
                  ความรับผิดของผลิตภัณฑ์/บริการ
                  ความรับผิดโดยสิ้นเชิงหรือตามหลักการอื่นใด)
                  หรือประเด็นฟ้องอื่นตามกฎหมาย ตามความเที่ยงธรรม ตามบทกฎหมาย
                  หรืออื่นใด ต่อ: <br />&nbsp;&nbsp;&nbsp;16.1.1 (1)
                  การสูญเสียการใช้งาน (2) การสูญเสียกำไร (3) การสูญเสียรายได้
                  (4) การสูญเสียข้อมูล (5) การสูญเสียค่าความนิยม หรือ (6)
                  การล้มเหลวในการรับรู้เงินออมที่คาดการณ์ไว้
                  ในแต่ละกรณีทั้งในทางตรงและทางอ้อม หรือ
                  <br />&nbsp;&nbsp;&nbsp;16.1.2 ความเสียหายทางอ้อม โดยบังเอิญ
                  พิเศษ หรือที่เป็นผลตามมา
                  ที่เกิดจากหรือเกี่ยวเนื่องกับการใช้หรือการไม่สามารถใช้แพลตฟอร์มนี้หรือบริการ
                  รวมถึงแต่ไม่จำกัดเพียง ความเสียหายอันเกิดจากสิ่งดังกล่าว
                  แม้ว่า MATCHDAY
                  จะได้รับแจ้งถึงความเป็นไปได้ของความเสียหายดังกล่าวแล้วก็ตาม
                  <br />16.2
                  คุณรับทราบและตกลงว่าสิทธิเพียงอย่างเดียวที่คุณมีเกี่ยวกับปัญหาหรือความไม่พึงพอใจใดๆ
                  ในบริการคือการขอให้ยกเลิกบัญชีของคุณ และ/หรือหยุดใช้งานบริการ
                  <br />16.3 โดยไม่คำนึงถึงความในส่วนก่อนหน้า หาก
                  MATCHDAYถูกศาลหรือเขตอำนาจศาลที่มีอำนาจสั่งให้ต้องรับผิด
                  (รวมถึงต่อการประมาทเลินเล่ออย่างร้ายแรง)
                  ภายใต้ขอบเขตสูงสุดที่กฎหมายที่เกี่ยวข้องอนุญาตไว้
                  ความรับผิดต่อคุณหรือบุคคลภายนอกใดๆ จะจำกัดอยู่ที่ 4,000 บาท
                </span>
              </p>

             

              <p class="c" style="margin-left: 20px; margin-right: 20px">
                <span style="font-size: 16px">
                  <b>17. ลิงก์ไปยังแพลตฟอร์มของบุคคลภายนอก</b><br /><br />
                  ลิงก์ของบุคคลภายนอกซึ่งมีอยู่ทั่วทั้งแพลตฟอร์ม
                  จะให้คุณสามารถออกจากแพลตฟอร์มนี้ได้
                  ลิงก์เหล่านี้จัดไว้ให้ตามมารยาทเท่านั้น
                  และแพลตฟอร์มที่มีลิงก์ไปไม่ได้อยู่ภายใต้การควบคุมของ MATCHDAY
                  ไม่ว่าในลักษณะใด
                  และคุณเข้าถึงแพลตฟอร์มเหล่านี้ด้วยความเสี่ยงของคุณเอง MATCHDAY
                  ไม่รับผิดชอบไม่ว่ากรณีใดต่อเนื้อหาของแพลตฟอร์มที่ลิงก์ไปดังกล่าว
                  หรือลิงก์ที่อยู่ในแพลตฟอร์มที่มีลิงก์ไป
                  รวมถึงการเปลี่ยนแปลงหรือการอัปเดตแพลตฟอร์มดังกล่าว MATCHDAY
                  จัดลิงก์เหล่านี้ไว้ให้เพื่อความสะดวกเท่านั้น และการรวมลิงก์ใดๆ
                  ไว้ไม่ได้แสดงนัยหรือการเชื่อมโยงอย่างชัดแจ้ง การรับรอง
                  หรือการเป็นผู้สนับสนุนโดย MATCHDAY ถึงแพลตฟอร์มที่ลิงก์ไปใดๆ
                  และ/หรือเนื้อหาในแพลตฟอร์มที่ลิงก์ไปนั้น
                </span>
              </p>

             

              <p class="c" style="margin-left: 20px; margin-right: 20px"></p>

              <p class="c" style="margin-left: 20px; margin-right: 20px">
                <span style="font-size: 16px">
                  <b>18. การมีส่วนร่วมของคุณต่อบริการ</b><br /><br />
                  18.1 การส่งเนื้อหาเพื่อรวมไว้ในบริการ
                  แสดงว่าคุณรับรองและรับประกันว่าคุณมีสิทธิและ/หรือการอนุญาตที่จำเป็นทั้งหมดเพื่อมอบสิทธิการใช้งานด้านล่างแก่
                  MATCHDAY คุณยังรับทราบและตกลงต่อไปว่า
                  คุณคือผู้รับผิดชอบแต่เพียงผู้เดียวต่อสิ่งใดก็ตามที่คุณโพสต์หรือทำให้ใช้งานได้บนหรือผ่านทางบริการ
                  รวมถึงแต่ไม่จำกัดเพียง ความถูกต้อง ความน่าเชื่อถือ ลักษณะ
                  การปลอดจากสิทธิ การตรงตามกฎหมายและข้อจำกัดทางกฎหมาย
                  ที่เกี่ยวข้องกับเนื้อหาที่ส่งเข้ามา
                  คุณมอบสิทธิการใช้งานตลอดกาล ที่เพิกถอนไม่ได้ เป็นสากล
                  ไม่ผูกขาด ปลอดค่าสิทธิ สามารถโอนสิทธิต่อไปได้ และเปลี่ยนมือได้
                  ให้แก่ MATCHDAY และผู้รับช่วงต่อ ในการใช้ คัดลอก แจกจ่าย
                  เผยแพร่ซ้ำ ส่งต่อ ดัดแปลง ปลับเปลี่ยน สร้างงานอนุพันธ์จาก
                  แสดงแก่สาธารณะ และดำเนินการต่อสาธารณะ
                  เนื้อหาที่ส่งเข้ามาดังกล่าวบน
                  ผ่านทางหรือที่เกี่ยวเนื่องกับบริการ ในรูปแบบสื่อใดๆ
                  และผ่านช่องทางสื่อใด รวมถึงแต่ไม่จำกัดเพียง
                  เพื่อการส่งเสริมการขายและแจกจ่ายต่อส่วนต่างๆ ของบริการ
                  (และงานอนุพันธ์ของบริการ)
                  โดยไม่ต้องมีอำนาจหน้าที่ที่ได้รับมอบหมาย
                  และคุณตกลงที่จะสละสิทธิทางศีลธรรมใดๆ
                  (และสิทธิที่คล้ายกันในส่วนต่างๆ ทั่วโลก) ในเรื่องดังกล่าว
                  คุณเข้าใจว่าสิ่งที่คุณส่งเข้ามานั้นอาจถูกส่งต่อทางเครือข่ายต่างๆ
                  และถูกเปลี่ยนเพื่อให้ตรงตามและปรับให้เข้ากับข้อกำหนดทางเทคนิค
                  <br />
                  18.2 วัสดุ ข้อมูล หรือแนวคิดใดที่คุณโพสต์บนหรือผ่านทางบริการ
                  หรือส่งต่อมายัง MATCHDAY ไม่ว่าด้วยวิธีใด (เรียกว่า
                  สิ่งที่ส่งมา) ไม่ถือว่าเป็นความลับโดย MATCHDAY
                  และอาจถูกเผยแพร่หรือใช้โดย MATCHDAY
                  โดยปราศจากการชดเชยหรือการรับผิดต่อคุณ
                  ไม่ว่าด้วยจุดประสงค์ใดก็ตาม รวมถึงแต่ไม่จำกัดเพียง การพัฒนา
                  การผลิต และการทำตลาดผลิตภัณฑ์ ในการส่งสิ่งที่ส่งมาแก่ MATCHDAY
                  คุณรับทราบและตกลงว่า MATCHDAY และ/หรือบุคคลภายนอกอื่นๆ
                  อาจพัฒนาซอฟต์แวร์ แอปพลิเคชั่น อินเทอร์เฟส ผลิตภัณฑ์
                  และส่วนปรับปรุงของสิ่งดังกล่าวโดยอิสระ ซึ่งมีลักษณะการทำงาน
                  รหัส
                  หรือลักษณะพิเศษอื่นตรงหรือคล้ายกับแนวคิดที่กำหนดไว้ในสิ่งที่ส่งมาของคุณ
                  ดังนั้นคุณจึงได้มอบสิทธิการใช้งานตลอดกาล ที่เพิกถอนไม่ได้
                  เป็นสากล ไม่ผูกขาด ปลอดค่าสิทธิ สามารถโอนสิทธิต่อไปได้
                  และเปลี่ยนมือได้ ให้แก่ MATCHDAY และผู้รับช่วงต่อ
                  ในการพัฒนาสิ่งที่ระบุไว้ข้างตน และเพื่อใช้ คัดลอก แจกจ่าย
                  เผยแพร่ซ้ำ ส่งต่อ ดัดแปลง ปรับเปลี่ยน สร้างงานอนุพันธ์จาก
                  แสดงแก่สาธารณะ และดำเนินการต่อสาธารณะ สิ่งที่ส่งมาดังกล่าวบน
                  ผ่านทางหรือที่เกี่ยวเนื่องกับบริการ ในรูปแบบสื่อใดๆ
                  และผ่านช่องทางสื่อใด รวมถึงแต่ไม่จำกัดเพียง
                  เพื่อการส่งเสริมการขายและแจกจ่ายต่อส่วนต่างๆ ของบริการ
                  (และงานอนุพันธ์ของบริการ)
                  โดยไม่ต้องมีอำนาจหน้าที่ที่ได้รับมอบหมาย
                  และคุณตกลงที่จะสละสิทธิทางศีลธรรมใดๆ (และงานอนุพันธ์จากบริการ)
                  ข้อกำหนดนี้ไม่ใช้บังคับกับข้อมูลส่วนบุคคลที่ต้องอยู่ภายใต้นโยบายความเป็นส่วนตัวของเรา
                  เว้นแต่ในขอบเขตที่คุณทำให้ข้อมูลส่วนบุคคลดังกล่าวใช้งานได้แก่สาธารณะบนหรือผ่านทางบริการ
                </span>
              </p>

             

              <p class="c" style="margin-left: 20px; margin-right: 20px"></p>

              <p class="c" style="margin-left: 20px; margin-right: 20px">
                <span style="font-size: 16px">
                  <b>19. การมีส่วนร่วมของบุคคลภายนอกต่อบริการและลิงก์ภายนอก</b
                  ><br /><br />
                  19.1 ผู้มีส่วนร่วมในบริการแต่ละคน ในด้านข้อมูล ข้อความ ภาพ
                  เสียง วิดีโอ ซอฟต์แวร์ และเนื้อหาอื่นๆ
                  เป็นผู้รับผิดชอบแต่เพียงผู้เดียวต่อความถูกต้อง ความน่าเชื่อถือ
                  ลักษณะ การปลอดจากสิทธิ การตรงตามกฎหมายและข้อจำกัดทางกฎหมาย
                  ที่เกี่ยวข้องกับเนื้อหาที่ส่งเข้ามา ดังนั้น MATCHDAY
                  จะไม่รับผิดชอบต่อ
                  และจะไม่เฝ้าติดตามหรือตรวจสอบอย่างสม่ำเสมอถึงความถูกต้อง
                  ความน่าเชื่อถือ ลักษณะ การปลอดจากสิทธิ
                  การตรงตามกฎหมายและข้อจำกัดทางกฎหมาย
                  ที่เกี่ยวข้องกับเนื้อหาที่ส่งเข้ามา คุณจะไม่กำหนดให้ MATCHDAY
                  ต้องรับผิดชอบต่อการดำเนินการหรือไม่ดำเนินการของผู้ใช้
                  รวมถึงแต่ไม่จำกัดเพียง
                  สิ่งที่โพสต์หรือทำให้ใช้งานได้ผ่านทางบริการ
                  <br />19.2 นอกจากนี้ บริการอาจมีลิงก์ไปยังผลิตภัณฑ์ เว็ปไซต์
                  บริการและข้อเสนอของบุคคลภายนอก ลิงก์ ผลิตภัณฑ์ เว็ปไซต์
                  บริการของบุคคลภายนอกเหล่านี้ไม่ได้เป็นของหรือควบคุมโดย
                  MATCHDAY แต่ดำเนินการโดย
                  และเป็นทรัพย์สินของบุคคลภายนอกที่เกี่ยวข้อง
                  และอาจได้รับการคุ้มครองตามกฎหมายลิขสิทธิ์หรือกฎหมายและสนธิสัญญาว่าด้วยทรัพย์สินทางปัญญาอื่นๆ
                  ที่บังคับใช้ MATCHDAYไม่ได้ตรวจสอบ และไม่รับผิดชอบต่อเนื้อหา
                  ฟังก์ชั่นการทำงาน ความปลอดภัย บริการ นโยบายความเป็นส่วนตัว
                  หรือการปฏิบัติอื่นๆ ของบุคคลภายนอกเหล่านี้
                  ขอแนะนำให้อ่านข้อกำหนดและนโยบายอื่นๆ
                  ที่เผยแพร่บนเว็ปไซต์หรืออื่นๆ โดยบุคคลภายนอกดังกล่าว
                  ในการใช้บริการ คุณตกลงว่า MATCHDAY
                  จะไม่รับผิดชอบในลักษณะใดก็ตามอันเนื่องมาจากการใช้งานของคุณหรือการไม่สามารถใช้งานเว็ปไซต์หรือวิดเจ็ตใดๆ
                  คุณรับทราบและตกลงต่อไปด้วยว่า MATCHDAY
                  อาจยกเลิกการใช้งานของคุณ หรือลบลิงก์ของบุคคลภายนอก
                  หรือแอปพลิเคชั่นบนบริการ
                  ตามขอบเขตที่การใช้งานนั้นละเมิดเงื่อนไขการให้บริการนี้
                </span>
              </p>

             

              <p class="c" style="margin-left: 20px; margin-right: 20px"></p>

              <p class="c" style="margin-left: 20px; margin-right: 20px">
                <span style="font-size: 16px">
                  <b>20. การรับรองและการรับประกันของคุณ</b><br /><br />
                  คุณรับรองและรับประกันว่า:
                  <br />
                  (1) คุณบรรลุนิติภาวะ (และในกรณีของผู้เยาว์
                  ได้รับความยินยอมจากพ่อแม่หรือผู้ปกครองตามกฎหมาย)
                  มีสิทธิและความสามารถที่จะเข้าทำสัญญาเงื่อนไขการให้บริการนี้และปฏิบัติตามเงื่อนไขนี้
                  และ <br />
                  (2)
                  คุณจะใช้บริการเพื่อจุดประสงค์ที่ถูกกฎหมายและตามเงื่อนไขการให้บริการนี้
                  และกฎหมาย กฎระเบียบ ประมวลกฎหมาย คำสั่ง แนวทางปฏิบัติ
                  นโยบายและข้อบังคับที่บังคับใช้ทั้งหมด
                </span>
              </p>

             

              <p class="c" style="margin-left: 20px; margin-right: 20px"></p>

              <p class="c" style="margin-left: 20px; margin-right: 20px">
                <span style="font-size: 16px">
                  <b>21. การชดใช้ค่าเสียหาย</b><br /><br />
                  คุณตกลงที่จะชดใช้ ปกป้องและทำให้ MATCHDAY ผู้ถือหุ้น
                  บริษัทย่อย บริษัทในกลุ่ม กรรมการ เจ้าหน้าที่ ตัวแทน
                  ผู้ร่วมแบรนด์ หรือหุ้นส่วนอื่น และพนักงาน (เรียกรวมว่า
                  ฝ่ายที่ได้รับการชดใช้) พ้นจากการเรียกร้องสิทธิ การดำเนินการ
                  การดำเนินคดี และการฟ้องร้องคดี รวมถึงหนี้สิน ความเสียหาย
                  การชำระหนี้ บทลงโทษ ค่าปรับ
                  ค่าใช้จ่ายและต้นทุนที่เกี่ยวข้องทั้งหมด
                  (รวมถึงแต่ไม่จำกัดเพียง ค่าใช้จ่ายในการยุติข้อพิพาทอื่นๆ)
                  ที่เกิดแก่ฝ่ายที่ได้รับการชดใช้ อันเกิดจากหรือเกี่ยวข้องกับ:
                  (1) การโฮสต์ การดำเนินงาน การจัดการและ/หรือการดูแลระบบบริการ
                  โดยหรือในนามของ MATCHDAY (2) การฝ่าฝืนหรือละเมิดเงื่อนไขใดๆ
                  ในเงื่อนไขการให้บริการนี้และนโยบายหรือแนวทางปฏิบัติอื่นใดที่อ้างถึงในที่นี่
                  (3) การใช้หรือใช้บริการในทางที่ผิด หรือ (4)
                  การละเมิดกฎหมายหรือสิทธิใดๆ ของบุคคลภายนอก
                </span>
              </p>

             

              <p class="c" style="margin-left: 20px; margin-right: 20px"></p>

              <p class="c" style="margin-left: 20px; margin-right: 20px">
                <span style="font-size: 16px">
                  <b>22. การแยกออกจากกันได้</b><br /><br />
                  หากบทบัญญัติใดในเงื่อนไขการให้บริการนี้ถือว่าผิดกฎหมาย
                  เป็นโมฆะ
                  หรือไม่สามารถบังคับใช้ได้ไม่ว่าด้วยเหตุผลใดภายใต้กฎหมายของเขตอำนาจศาลใด
                  ให้ถือว่าบทบัญญัตินั้นสามารถแยกออกจากกันได้จากข้อกำหนดและเงื่อนไขนี้
                  และไม่ส่งผลต่อความสมบูรณ์และการมีผลบังคับใช้ของบทบัญญัติที่เหลือ
                  หรือความสมบูรณ์และการมีผลบังคับใช้ของบทบัญญัติที่มีปัญหาภายใต้กฎหมายของเขตอำนาจศาลอื่นใด
                </span>
              </p>

             

              <p class="c" style="margin-left: 20px; margin-right: 20px"></p>

              <p class="c" style="margin-left: 20px; margin-right: 20px">
                <span style="font-size: 16px">
                  <b>23. บทบัญญัติทั่วไป</b><br /><br />
                  23.1 MATCHDAY
                  สงวนสิทธิ์ทั้งหมดที่ไม่ได้ให้ไว้โดยชัดแจ้งในที่นี้
                  <br />23.2
                  เงื่อนไขการให้บริการนี้จะอยู่ภายใต้บังคับและตีความตามกฎหมายของประเทศไทย
                  <br />23.2 MATCHDAY
                  อาจแก้ไขเงื่อนไขการให้บริการนี้ได้ตลอดเวลาโดยโพสต์เงื่อนไขการให้บริการฉบับแก้ไขบนแพลตฟอร์มนี้
                  การใช้งานแพลตฟอร์มนี้ต่อไปของคุณหลังการเปลี่ยนแปลงดังกล่าวจะถือว่าคุณทำความเข้าใจอย่างละเอียดและยอมรับเงื่อนไขการให้บริการฉบับแก้ไขนั้นแล้ว
                  <br />23.3 คุณต้องไม่มอบหมาย ให้สิทธิการใช้งานต่อ
                  หรือโอนสิทธิที่มอบให้แก่คุณในที่นี้หรือทำสัญญาช่วงข้อผูกพันใดๆ
                  ของคุณ <br />
                  23.4
                  ไม่มีสิ่งใดในเงื่อนไขการให้บริการนี้จะถือเป็นการเป็นหุ้นส่วน
                  การลงทุนร่วม หรือความสัมพันธ์ระหว่างตัวการกับตัวแทน
                  ระหว่างคุณและ MATCHDAY
                  และไม่มีสิ่งใดอนุญาตให้คุณสร้างภาระค่าใช้จ่ายหรือหนี้สินในนามของ
                  MATCHDAY <br />
                  23.5 การสละสิทธิใดของ MATCHDAY ไม่ว่าเวลาใด
                  ที่จะบังคับใช้บทบัญญัติใดของเงื่อนไขนี้
                  จะต้องทำเป็นลายลักษณ์อักษรและไม่ส่งผลไม่ว่าในทางใดต่อสิทธิของตนในภายหลังที่จะบังคับใช้บทบัญญัติดังกล่าว
                  <br />
                  23.6
                  เงื่อนไขการให้บริการนี้มีไว้เพื่อคุณและประโยชน์ของเราเท่านั้น
                  และไม่ใช่เพื่อประโยชน์ของบุคคลหรือนิติบุคคลอื่นใด
                  เว้นแต่บริษัทย่อยและบริษัทในกลุ่มของ MATCHDAY
                  (และผู้รับช่วงและผู้รับมอบหมายของบริษัทย่อยและบริษัทในกลุ่มที่เกี่ยวข้องของ
                  MATCHDAY แต่ละแห่ง) <br />
                  23.7
                  เงื่อนไขที่กำหนดไว้ในเงื่อนไขการให้บริการนี้และข้อตกลงรวมถึงนโยบายใดๆ
                  ที่รวมอยู่ในหรืออ้างถึงในเงื่อนไขการให้บริการนี้
                  ถือเป็นข้อตกลงที่สมบูรณ์
                  และความเข้าใจของคู่สัญญาอันเกี่ยวกับบริการและแพลตฟอร์ม
                  และใช้แทนที่ข้อตกลงหรือความเข้าใจก่อนหน้านี้ระหว่างคู่สัญญาซึ่งเกี่ยวข้องกับเรื่องดังกล่าว
                  ดังนั้นคู่สัญญาจึงยกเว้นเงื่อนไขโดยนัยทั้งหมดตามข้อเท็จจริง
                  ในการเข้าทำข้อตกลงที่เกิดจากเงื่อนไขการให้บริการนี้นั้น
                  คู่สัญญาไม่ได้พึ่งพาถ้อยแถลง การรับรอง การรับประกัน ความเข้าใจ
                  ภาระหน้าที่ คำสัญญา
                  หรือคำมั่นจากบุคคลใดนอกเหนือจากที่ระบุไว้อย่างชัดแจ้งในเงื่อนไขการให้บริการนี้
                  <br />
                  23.8 การที่ MATCHDAY
                  ไม่ใช้หรือบังคับใช้สิทธิ์หรือข้อกำหนดของแพลตฟอร์ม MATCHDAY
                  เหล่านี้
                  จะไม่ถือว่าเป็นการสละสิทธิ์ในข้อกำหนดหรือสิทธิ์ดังกล่าวแต่อย่างใด
                  <br />
                  23.9
                  หากผู้ใช้งานมีคำถามหรือข้อกังวลเกี่ยวกับข้อกำหนดและเงื่อนไขการให้บริการนี้
                  หรือมีปัญหาที่เกิดจากเงื่อนไขการให้บริการหรือในแพลตฟอร์ม
                  โปรดติดต่อเราได้ที่ อีเมล admin@matchday.co.th
                </span>
              </p>

             

              <p class="c" style="margin-left: 20px; margin-right: 20px">
                <span style="font-size: 16px">
                  ข้าพเจ้าได้อ่านข้อตกลงนี้และตกลงยอมรับบทบัญญัติทั้งหมดข้างต้นและการแก้ไขบทบัญญัติใดๆ
                  ที่จะเกิดตามมาในภายหลังจากการคลิกปุ่มสมัครใช้งาน
                  ข้าพเจ้ารับทราบและเข้าใจว่าข้าพเจ้ากำลังสร้างลายเซ็นดิจิทัล
                  ซึ่งข้าพเจ้าประสงค์ให้มีผลบังคับใช้เสมือนว่าข้าพเจ้าได้ลงชื่อด้วยตัวเอง
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
    <br />
  </div>
</template>

<script>
export default {
  mounted() {
    document.getElementById("terms").scrollIntoView();
  }
};
</script>

<style>
.divider {
  height: 1px;
  width: 95%;
  border-bottom: 1px #707070 solid;
}
</style>
